import React, { useState } from "react";
import { injectIntl } from "react-intl";
import { v1 as uuidv1 } from "uuid";
import { createBaseEvent } from "../../Base/Utilities/CreateBaseEvent";
import { sendPossessionEvent } from "../../Base/Utilities/PossessionEvents";
import { getEventTime, sendEvent } from "../../Base/Utilities/SendEvent";
import TypeButton from "../ButtonPanel/TypeButton/TypeButton";

import "./ThrowOff.scss";

const ThrowOff = (props) => {
  const { currentState, intl, updateState } = props;
  const { formatMessage } = intl;

  const [winner, updateWinner] = useState(null);
  const [goalkeepers, setGoalkeepers] = useState({});

  const handleConfirm = () => {
    const playId = uuidv1();
    const winAction = createBaseEvent(currentState, playId);
    winAction.event.eventType = "throwOff";
    winAction.event.entityId = winner;
    sendEvent(winAction, props);

    sendPossessionEvent(winner, "hasPossession", props);
    const newEntities = currentState.entities.map((entity) => {
      return {
        ...entity,
        hasPossession: entity.entityId === winner,
      };
    });
    updateState("entities", newEntities);

    updateState("primary", null);
  };

  const isGoalkeeperSelected = () => {
    return currentState.entities.every((entity) => !!goalkeepers[entity.entityId]);
  };

  const onGoalkeeperChange = (personId, entity) => {
    const person = entity.persons.find(
      (person) => person.personId === personId
    );

    sendPlayerEvent(personId, entity);
    setGoalkeepers({
      ...goalkeepers,
      [entity.entityId]: personId,
    });
    updateState(
      "entities",
      currentState.entities.map((currentEntity) => {
        if (currentEntity.entityId !== entity.entityId) {
          return currentEntity;
        }

        return {
          ...currentEntity,
          persons: currentEntity.persons.map((person) => ({
            ...person,
            active:
              person.position !== "G"
                ? person.active
                : person.personId === personId,
          })),
        };
      })
    );
  };

  const sendPlayerEvent = (personId, entity) => {
    const timestamp = new Date();
    const clockParts = currentState.clock.displayTime.split(":");
    const msg = {
      class: "sport",
      eventType: "goalKeeperChange",
      entityId: entity.entityId,
      personId: personId,
      eventId: uuidv1(),
      playId: uuidv1(),
      periodId: currentState.period.periodId,
      timestamp: timestamp.toISOString(),
      eventTime: getEventTime(),
      clock:
        "PT" + parseInt(clockParts[0]) + "M" + parseInt(clockParts[1]) + "S",
    };

    const subAction = {
      action: "sendEvent",
      event: msg,
      type: "custom",
    };

    sendEvent(subAction, props);
  };

  return (
    <>
      <h2 className="align-center">
        {formatMessage({
          id: "throwOff.teamSelect",
          defaultMessage: "Team to throw off",
        })}
      </h2>
      <div className="team-select-buttons">
        {currentState.entities.map((team, index) => {
          const panel = {
            name: "throwOff",
            options: { freeText: team.fullNameLatin },
            showOn: [!winner || team.entityId === winner],
            layout: `team-button selectable-${!!winner}`,
          };

          return (
            <TypeButton
              {...props}
              key={team.entityId}
              panel={panel}
              team={index}
              style={{
                background: team.primaryColor,
              }}
              onClick={() => updateWinner(team.entityId)}
            />
          );
        })}
      </div>
      <h2 className="align-center">
        {formatMessage({
          id: "throwOff.goalKeeper",
          defaultMessage: "Active Goalkeepers",
        })}
      </h2>
      <div className="goalkeeper-dropdowns">
        {currentState.entities.map((entity, index) => {
          return (
            <select
              key={entity.entityId}
              onChange={(ev) => onGoalkeeperChange(ev.target.value, entity)}
              value={goalkeepers[entity.entityId] || ''}
            >
              <option value={''}>--Select--</option>
              {entity.persons
                .filter((person) => person.position === "G")
                .map((person) => (
                  <option key={person.personId} value={person.personId}>
                    {person.bib} - {person.name}
                  </option>
                ))}
            </select>
          );
        })}
      </div>
      <div
        className={`enabled-${!!winner && isGoalkeeperSelected()} match-button confirm`}
        onClick={() => handleConfirm()}
      >
        {formatMessage({
          id: "confirm",
          defaultMessage: "Confirm",
        })}
      </div>
    </>
  );
};

export default injectIntl(ThrowOff);
